import { keyframes } from "styled-components";

export const inputLine = keyframes`
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
`;
