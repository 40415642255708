import React from "react";
import { ReactComponent as Student1 } from "../../image/icons/students/Student 1.svg";
import { ReactComponent as Student2 } from "../../image/icons/students/Student 2.svg";
import { ReactComponent as Student3 } from "../../image/icons/students/Student 3.svg";
import { ReactComponent as Student4 } from "../../image/icons/students/Student 4.svg";
import { ReactComponent as Student5 } from "../../image/icons/students/Student 5.svg";
import { ReactComponent as Student6 } from "../../image/icons/students/Student 6.svg";
import { ReactComponent as Student7 } from "../../image/icons/students/Student 7.svg";
import { ReactComponent as Student8 } from "../../image/icons/students/Student 8.svg";
import { ReactComponent as Student9 } from "../../image/icons/students/Student 9.svg";
import { ReactComponent as Student10 } from "../../image/icons/students/Student 10.svg";
import { ReactComponent as Student11 } from "../../image/icons/students/Student 11.svg";
import { ReactComponent as Student12 } from "../../image/icons/students/Student 12.svg";
import { ReactComponent as Student13 } from "../../image/icons/students/Student 13.svg";
import { ReactComponent as Student14 } from "../../image/icons/students/Student 14.svg";
import { ReactComponent as Student15 } from "../../image/icons/students/Student 15.svg";
import { ReactComponent as Student16 } from "../../image/icons/students/Student 16.svg";
import { ReactComponent as Student17 } from "../../image/icons/students/Student 17.svg";
import { ReactComponent as Student18 } from "../../image/icons/students/Student 18.svg";
import { ReactComponent as Student19 } from "../../image/icons/students/Student 19.svg";
import { ReactComponent as Student20 } from "../../image/icons/students/Student 20.svg";
import { ReactComponent as Student21 } from "../../image/icons/students/Student 21.svg";
import { ReactComponent as Student22 } from "../../image/icons/students/Student 22.svg";
import { ReactComponent as Student23 } from "../../image/icons/students/Student 23.svg";
import { ReactComponent as Student24 } from "../../image/icons/students/Student 24.svg";
import { ReactComponent as Student25 } from "../../image/icons/students/Student 25.svg";
import { ReactComponent as Student26 } from "../../image/icons/students/Student 26.svg";
import { ReactComponent as Student27 } from "../../image/icons/students/Student 27.svg";
import { ReactComponent as Student28 } from "../../image/icons/students/Student 28.svg";
import { ReactComponent as Student29 } from "../../image/icons/students/Student 29.svg";
import { ReactComponent as Student30 } from "../../image/icons/students/Student 30.svg";
import { ReactComponent as Student31 } from "../../image/icons/students/Student 31.svg";
import { ReactComponent as Student32 } from "../../image/icons/students/Student 32.svg";
import { ReactComponent as Student33 } from "../../image/icons/students/Student 33.svg";
import { ReactComponent as Student34 } from "../../image/icons/students/Student 34.svg";
import { ReactComponent as Student35 } from "../../image/icons/students/Student 35.svg";
import { ReactComponent as Student36 } from "../../image/icons/students/Student 36.svg";
import { ReactComponent as Student37 } from "../../image/icons/students/Student 37.svg";
import { ReactComponent as Student38 } from "../../image/icons/students/Student 38.svg";
import { ReactComponent as Student39 } from "../../image/icons/students/Student 39.svg";
import { ReactComponent as Student40 } from "../../image/icons/students/Student 40.svg";
import { ReactComponent as Student41 } from "../../image/icons/students/Student 41.svg";
import { ReactComponent as Student42 } from "../../image/icons/students/Student 42.svg";
import { ReactComponent as Student43 } from "../../image/icons/students/Student 43.svg";
import { ReactComponent as Student44 } from "../../image/icons/students/Student 44.svg";
import { ReactComponent as Student45 } from "../../image/icons/students/Student 45.svg";
import { ReactComponent as Student46 } from "../../image/icons/students/Student 46.svg";
import { ReactComponent as Student47 } from "../../image/icons/students/Student 47.svg";
import { ReactComponent as Student48 } from "../../image/icons/students/Student 48.svg";
import { ReactComponent as Student49 } from "../../image/icons/students/Student 49.svg";
import { ReactComponent as Student50 } from "../../image/icons/students/Student 50.svg";

export const IcStudent1 = () => {
  return <Student1 />;
};

export const IcStudent2 = () => {
  return <Student2 />;
};

export const IcStudent3 = () => {
  return <Student3 />;
};

export const IcStudent4 = () => {
  return <Student4 />;
};

export const IcStudent5 = () => {
  return <Student5 />;
};

export const IcStudent6 = () => {
  return <Student6 />;
};

export const IcStudent7 = () => {
  return <Student7 />;
};

export const IcStudent8 = () => {
  return <Student8 />;
};

export const IcStudent9 = () => {
  return <Student9 />;
};

export const IcStudent10 = () => {
  return <Student10 />;
};

export const IcStudent11 = () => {
  return <Student11 />;
};

export const IcStudent12 = () => {
  return <Student12 />;
};

export const IcStudent13 = () => {
  return <Student13 />;
};

export const IcStudent14 = () => {
  return <Student14 />;
};

export const IcStudent15 = () => {
  return <Student15 />;
};

export const IcStudent16 = () => {
  return <Student16 />;
};

export const IcStudent17 = () => {
  return <Student17 />;
};

export const IcStudent18 = () => {
  return <Student18 />;
};

export const IcStudent19 = () => {
  return <Student19 />;
};

export const IcStudent20 = () => {
  return <Student20 />;
};

export const IcStudent21 = () => {
  return <Student21 />;
};

export const IcStudent22 = () => {
  return <Student22 />;
};

export const IcStudent23 = () => {
  return <Student23 />;
};

export const IcStudent24 = () => {
  return <Student24 />;
};

export const IcStudent25 = () => {
  return <Student25 />;
};

export const IcStudent26 = () => {
  return <Student26 />;
};

export const IcStudent27 = () => {
  return <Student27 />;
};

export const IcStudent28 = () => {
  return <Student28 />;
};

export const IcStudent29 = () => {
  return <Student29 />;
};

export const IcStudent30 = () => {
  return <Student30 />;
};

export const IcStudent31 = () => {
  return <Student31 />;
};

export const IcStudent32 = () => {
  return <Student32 />;
};

export const IcStudent33 = () => {
  return <Student33 />;
};

export const IcStudent34 = () => {
  return <Student34 />;
};

export const IcStudent35 = () => {
  return <Student35 />;
};

export const IcStudent36 = () => {
  return <Student36 />;
};

export const IcStudent37 = () => {
  return <Student37 />;
};

export const IcStudent38 = () => {
  return <Student38 />;
};

export const IcStudent39 = () => {
  return <Student39 />;
};

export const IcStudent40 = () => {
  return <Student40 />;
};

export const IcStudent41 = () => {
  return <Student41 />;
};

export const IcStudent42 = () => {
  return <Student42 />;
};

export const IcStudent43 = () => {
  return <Student43 />;
};

export const IcStudent44 = () => {
  return <Student44 />;
};

export const IcStudent45 = () => {
  return <Student45 />;
};

export const IcStudent46 = () => {
  return <Student46 />;
};

export const IcStudent47 = () => {
  return <Student47 />;
};

export const IcStudent48 = () => {
  return <Student48 />;
};

export const IcStudent49 = () => {
  return <Student49 />;
};

export const IcStudent50 = () => {
  return <Student50 />;
};
