import styled from "styled-components";

export default styled.div`
  svg {
    display: flex;
    font-size: 2em;
    font-size: 2rem;
    cursor: pointer;
  }
`;
