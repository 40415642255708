// 이용약관
export const agreePolicyOne = `티처캔 이용 약관\n
제1조(목적)
본 회원약관은 팀초코(이하 '갑')가 운영하는 인터넷관련 서비스(이하 '티처캔')를 이용함에 있어 관리자와 이용자(이하 '회원')의 권리, 의무 및 책임사항을 규정함을 목적으로 합니다.\n
제2조 (약관의 효력)
1. 본 약관은 회원 가입 시 회원들에게 통지함으로써 효력이 발생합니다.
2. '갑'은 이 약관의 내용을 변경할 수 있으며, 변경된 약관은 제1항과 같은 방법으로 공지 또는 통지함으로써 효력을 발생합니다.
3. 약관의 변경사항 및 내용은 '갑'의 홈페이지에 게시하는 방법으로 공시합니다.\n
제3조 (약관 이외의 준칙)
이 약관에 명시되지 않은 사항이 전기 통신 기본법, 전기통신 사업법, 기타 관련 법령에 규정되어 있는 경우 그 규정에 따릅니다.\n
제4조 (이용계약의 체결)
회원 가입 시 회원 약관 밑에 있는 회원가입 버튼을 누르면 약관에 동의하여 이 계약이 체결된 것으로 간주합니다.\n
제5조 (용어의 정의)
이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
1. 회원: '갑'과 서비스 이용에 관한 계약을 체결한 자
2.아이디(ID): 회원 식별과 회원의 서비스 이용을 위하여 회원이 선정하고 '갑'이 승인하는 문자와 숫자의 조합
3. 비밀번호: 회원이 통신상의 자신의 비밀을 보호하기 위해 선정한 문자와 숫자, 특수 문자의 조합\n
제6조 (이용신청)
1. 회원 가입은 온라인으로 가입신청 양식에 기록하여 '갑'에 제출함으로써 이용신청을 할 수 있습니다.
2. 가입희망 회원은 반드시 사용중인 이메일로 이용신청을 하여야 합니다.\n
제7조 (회원가입의 승낙)
'갑'의 회원 가입 신청 양식에 가입 희망 회원이 인터넷으로 제6조와 같이 신청하면 가입이 완료되어 바로 로그인 서비스를 이용할 수 있습니다.\n
제8조(회원가입 신청거절 및 강제 탈퇴)
1. '갑'은 ‘을’이 타인의 이메일을 도용하여 회원가입 신청을 할 경우 회원가입 신청을 거절할 수 있습니다.
2. '을'의 회원가입 신청이 승인된 후에도 이메일을 도용한 사실이 발각될 경우 '갑'은 '을'의 회원 자격을 박탈시킬 수 있습니다.\n
제9조 (서비스 제공의 중지)
'갑'은 다음의 경우 서비스의 제공을 중지할 수 있습니다
1. 설비의 보수 등을 위하여 부득이한 경우
2. 전기통신사업법에 규정된 기간통신사업자가 전기통신서비스를 중지하는 경우
3.기타 '갑'이 서비스를 제공할 수 없는 사유가 발생한 경우\n
제10조 ('갑'의 의무)
1. '갑'은 계속적, 안정적으로 서비스를 제공할 수 있도록 최선의 노력을 다하여야 합니다.
2.'갑'은 항상 서비스의 보안 및 관리에 만전을 기함으로써 정보보안에 최선을 다하여야 합니다.\n
제11조 (개인정보보호)
1.'갑'은 이용자의 정보수집 시 서비스의 제공에 필요한 최소한의 정보를 수집합니다.
2.제공된 개인정보는 당해 이용자의 동의 없이 목적 외의 이용이나 제3자에게 제공할 수 없으며, 이에 대한 모든 책임은 '갑'이 집니다. 다만, 다음의 경우에는 예외로 합니다.
 ①통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우
 ②전기통신기본법 등 법률의 규정에 의해 국가기관의 요구가 있는 경우
 ③범죄에 대한 수사상의 목적이 있거나 정보통신윤리 위원회의 요청이 있는 경우
 ④기타 관계법령에서 정한 절차에 따른 요청이 있는 경우
3.회원은 언제든지 '갑'이 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 '갑'은 이에 대해 지체없이 처리합니다.\n
제12조 (회원의 의무)
1.회원은 관계법령, 이 약관의 규정, 이용안내 및 주의사항 등 '갑'이 통지하는 사항을 준수하여야 하며, 기타 '갑'의 업무에 방해되는 행위를 하여서는 안됩니다.
2.회원은 '갑'의 사전 승낙 없이 서비스를 이용하여 어떠한 영리 행위도 할 수 없습니다.
3.회원은 서비스를 이용하여 얻은 정보를 '갑'의 사전 승낙 없이 복사, 복제, 변경, 번역, 출판, 방송 기타의 방법으로 사용하거나 이를 타인에게 제공할 수 없습니다.
4.회원은 이용신청서의 기재 내용 중 변경된 내용이 있는 경우 서비스를 통하여 그 내용을 '갑'에게 통지하여야 합니다.
5.회원은 서비스 이용과 관련하여 다음 각 호의 행위를 하여서는 안됩니다.
 ①다른 회원의 아이디(ID)를 부정 사용하는 행위
 ②범죄행위를 목적으로 하거나 기타 범죄행위와 관련된 행위
 ③선량한 풍속, 기타 사회질서를 해하는 행위
 ④타인의 명예를 훼손하거나 모욕하는 행위
 ⑤타인의 지적재산권 등의 권리를 침해하는 행위
 ⑥해킹행위 또는 컴퓨터바이러스의 유포행위
 ⑦타인의 의사에 반하여 광고성 정보 등 일정한 내용을 지속적으로 전송 또는 타 사이트를 링크하는 행위
 ⑧서비스의 안전적인 운영에 지장을 주거나 줄 우려가 있는 일체의 행위
 ⑨기타 관계법령에 위배되는 행위
 ⑩게시판 등 커뮤니티를 통한 상업적 광고 홍보 또는 상거래 행위\n
제13조 (게시물 또는 내용물의 삭제)
'갑'은 서비스의 게시물 또는 내용물이 제12조의 규정에 위반되거나 소정의 게시 기간을 초과하는 경우 사전 통지나 동의 없이 이를 삭제할 수 있습니다.\n
제14조 (게시물에 대한 권리·의무)
게시물에 대한 저작권을 포함한 모든 권리 및 책임은 이를 게시한 회원에게 있습니다.\n
제15조 (양도금지)
회원이 서비스의 이용권한, 기타 이용계약상 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.\n
제16조 (계약해지 및 이용제한)
1.회원이 이용계약을 해지하고자 하는 때에는 본인이 서비스 또는 전자 우편을 통하여 해지하고자 하는 날의 1일전까지 (단, 해지일이 법정 공휴일인 경우 공휴일 개시 2일전까지)이를 '갑'에게 신청하여야 합니다.
2.'갑'은 해지 및 탈퇴를 한 회원이 다시 이용신청을 하는 경우 일정기간 그 승낙을 제한할 수 있습니다.\n
제17조 (면책·배상)
1. '갑'은 회원이 서비스에 게재한 정보, 자료, 사실의 정확성, 신뢰성 등 그 내용에 관하여는 어떠한 책임을 부담하지 아니하고, 회원은 자기의 책임 아래 서비스를 이용하며, 서비스를 이용하여 게시 또는 전송한 자료 등에 관하여 손해가 발생하거나 자료의 취사 선택, 기타서비스 이용과 관련하여 어떠한 불이익이 발생하더라도 이에 대한 모든 책임은 회원에게 있습니다.
2. 회원 아이디(이메일 주소)와 비밀번호의 관리 및 이용상의 부주의로 인하여 발생 되는 손해 또는 제3자에 의한 부정 사용 등에 대한 책임은 모두 회원에게 있습니다.
3. 회원이 제12조, 기타 이 약관의 규정을 위반함으로 인하여 '갑'이 회원 또는 제3자에 대하여 책임을 부담하게 되고, 이로써 '갑'에게 손해가 발생하게 되는 경우, 이 약관을 위반한 회원은 '갑'에게 발생하는 모든 손해를 배상하여야 하며, 동 손해로부터 '갑'을 면책시켜야 합니다.\n
제18조 (분쟁의 해결)
1.'갑'과 회원은 서비스와 관련하여 발생한 분쟁을 원만하게 해결하기 위하여 필요한 모든 노력을 하여야 합니다.
2.1항의 규정에도 불구하고 분쟁으로 인하여 소송이 제기될 경우 소송은 '갑'의 소재지를 관할하는 법원의 관할로 합니다.\n
부 칙
제 1 조 (시행일) 이 약관은 2022년 02월 21일부터 시행합니다.`;

export const agreePolicyTwo = `< 티처캔 >(‘https://teachercan.com’이하 ‘TeacherCan’)은 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.\n
○ 이 개인정보처리방침은 2022년 2월 21부터 적용됩니다.\n
제1조(개인정보의 처리 목적)
< 티처캔 >(‘https://teachercan.com’이하 ‘TeacherCan’)은 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행해야 합니다.
1. 홈페이지 회원가입 및 관리
-회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 서비스 부정 이용 방지 목적으로 개인정보를 처리합니다.
2. 서비스 제공
-콘텐츠 제공, 맞춤 서비스 제공을 목적으로 개인정보를 처리합니다.\n
제2조(개인정보의 처리 및 보유 기간)
① < 티처캔 >은 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.
② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
<홈페이지 회원가입 및 관리>
 ‘홈페이지 회원가입 및 관리‘와 관련한 개인정보는 수집 및 이용에 관한 동의일로부터 ‘회원 탈퇴 시’까지 위 이용목적을 위하여 보유 및 이용됩니다.
 - 보유근거 : 정보주체 동의
<회원 가입 정보>
 - 보존 항목 : 이메일
 - 보존 이유 : 회원자격 확인 및 부정 가입 방지
 - 보존 기간 : 회원 탈퇴 시까지\n
제3조(정보주체와 법정대리인의 권리·의무 및 그 행사방법)
① 정보주체는 티처캔에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.
② 제1항에 따른 권리 행사는 티처캔에 대해 「개인정보 보호법」 시행령 제41조 제1항에 따라 전자우편 등을 통하여 하실 수 있으며 티처캔은 이에 대해 지체 없이 조치하겠습니다.
③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.
⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
⑥ 티처캔은 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.\n
제4조(처리하는 개인정보의 항목 작성)
① < 티처캔 >은 다음의 개인정보 항목을 처리하고 있습니다.
1. < 홈페이지 회원가입 및 관리 >
  -필수항목 : 이메일, 비밀번호
2. < 서비스 제공 >
  -선택항목 : 근무 학교명\n
제5조(개인정보의 파기)
① < 티처캔 >은 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
② 개인정보 파기의 절차 및 방법은 다음과 같습니다.
1. 파기절차
< 티처캔 >은 파기 사유가 발생한 개인정보를 선정하고, < 티처캔 > 의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
2. 파기방법
전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다\n
제6조(개인정보의 안전성 확보 조치)
< 티처캔 >은 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
1. 해킹 등에 대비한 기술적 대책
<티처캔>(‘TeacherCan’)은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적으로 감시 및 차단하고 있습니다.
2. 개인정보의 암호화
이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.
3. 개인정보에 대한 접근 제한
개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.\n
제7조(개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항)
① 티처캔은 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.
② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.
가. 쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.
나. 쿠키의 설치•운영 및 거부 : 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.
다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.\n
제8조 (개인정보 보호책임자 및 관리담당자)
:앞쪽_화살표: 티처캔 개인정보 관리책임자
성명 : 김홍동
직위 : 대표운영자
메일 : nlom0218@naver.com,
:앞쪽_화살표: 티처캔 개인정보 관리담당자
성명 : 이진승
직위 : 팀초코 개발팀
메일 : superanomie89@gmail.com,\n
제10조(권익침해 구제방법)
정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)
2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)
3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)
4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)
「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.
※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.\n
제11조(개인정보 처리방침 변경)
① 이 개인정보처리방침은 2022년 2월 21부터 적용됩니다.`;
