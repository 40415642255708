import alerm1 from "./기본알림음1.MP3";
import alerm2 from "./비상밸 소리.MP3";
import alerm3 from "./기본알림음2.mp3";
import alerm4 from "./기본알림음3.mp3";
import alerm5 from "./탁상시계.mp3";
import alerm6 from "./꼬꼬덱.MP3";

export const alermAudiocArr = [
  { name: "기본알림음1", audio: alerm1 },
  { name: "기본알림음2", audio: alerm3 },
  { name: "기본알림음3", audio: alerm4 },
  { name: "비상벨", audio: alerm2 },
  { name: "탁상시계", audio: alerm5 },
  { name: "꼬꼬덱", audio: alerm6 },
];
