import styled from "styled-components";

const RegisterErrMsg = styled.div`
  text-align: center;
  color: ${(props) => props.theme.redColor};
  transition: color 1s ease;
  padding-bottom: 20px;
  padding-bottom: 1.25rem;
`;

export default RegisterErrMsg;
