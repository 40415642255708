import styled from "styled-components";

const AccountForm = styled.form`
  width: 100%;
  display: grid;
  row-gap: 40px;
  row-gap: 2.5rem;
`;

export default AccountForm;
