import { keyframes } from "styled-components";

export const FadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 0.8;
  }
`;

export const CardFadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 0.9;
  }
`;

export const BtnFadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
